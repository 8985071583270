/* refer to https://www.canva.com/colors/color-wheel/ for colors selection */
/* Master theme */
$ddc-background-master: url("~assets/img/ddc-papier-dechire-master.png");
$ddc-color-master-primary: #003a5d;
$ddc-color-master-secondary: #0f364f;
$ddc-color-master-tertiary: #00456f;
$ddc-color-master-quaternary: #0a5780;
$ddc-color-master-muted-primary: #61aad0;
$ddc-color-master-muted-secondary: #aed3e7;
$ddc-color-master-muted-tertiary: #e4f1f7;
$ddc-color-master-warn: #ff6666;

/* Marins theme */
$ddc-background-marins: url("~assets/img/ddc-papier-dechire-marins.png");
$ddc-color-marins-primary: #0077b3;
$ddc-color-marins-secondary: #00669a;
$ddc-color-marins-tertiary: #297EAA;
$ddc-color-marins-quaternary: #3b96c4;
$ddc-color-marins-muted-primary: #78cffb;
$ddc-color-marins-muted-secondary: #bae8ff;
$ddc-color-marins-muted-tertiary: #e1f5ff;
$ddc-color-marins-warn: #ff6666;

/* AUDACE theme */
$ddc-background-audace: url("~assets/img/ddc-papier-dechire-audace.png");
$ddc-color-audace-primary: #6e74aa;
$ddc-color-audace-secondary: #474b7b;
$ddc-color-audace-tertiary: #64699d;
$ddc-color-audace-quaternary: #a393bf;
$ddc-color-audace-muted-primary: #beb7df;
$ddc-color-audace-muted-secondary: #dad6ed;
$ddc-color-audace-muted-tertiary: #ebe9f5;
$ddc-color-audace-warn: #e85f5c;

/* Compagnons theme */
$ddc-background-compagnons: url("~assets/img/ddc-papier-dechire-compagnons.png");
$ddc-color-compagnons-primary: #007254;
$ddc-color-compagnons-secondary: #30826c;
$ddc-color-compagnons-tertiary: #70b77e;
$ddc-color-compagnons-quaternary: #77c67b;
$ddc-color-compagnons-muted-primary: #b0e19f;
$ddc-color-compagnons-muted-secondary: #e2f7db;
$ddc-color-compagnons-muted-tertiary: #f0fbec;
$ddc-color-compagnons-warn: #ea5633;

/* Pionniers et Caravelles theme */
$ddc-background-pionniers-et-caravelles: url("~assets/img/ddc-papier-dechire-pionniers-et-caravelles.png");
$ddc-color-pionners-et-caravelles-primary: #d03f15;
$ddc-color-pionners-et-caravelles-secondary: #c5512e;
$ddc-color-pionners-et-caravelles-tertiary: #bd4827;
$ddc-color-pionners-et-caravelles-quaternary: #ed704c;
$ddc-color-pionners-et-caravelles-muted-primary: #f3a28b;
$ddc-color-pionners-et-caravelles-muted-secondary: #fad5ca;
$ddc-color-pionners-et-caravelles-muted-tertiary: #fef2ef;
$ddc-color-pionners-et-caravelles-warn: #db324d;

/* Scouts et Guides theme */
$ddc-background-scouts-et-guides: url("~assets/img/ddc-papier-dechire-scouts-et-guides.png");
$ddc-color-scouts-et-guides-primary: #0077b3;
$ddc-color-scouts-et-guides-secondary: #00669a;
$ddc-color-scouts-et-guides-tertiary: #297EAA;
$ddc-color-scouts-et-guides-quaternary: #3b96c4;
$ddc-color-scouts-et-guides-muted-primary: #78cffb;
$ddc-color-scouts-et-guides-muted-secondary: #bae8ff;
$ddc-color-scouts-et-guides-muted-tertiary: #e1f5ff;
$ddc-color-scouts-et-guides-warn: #ff6666;

/* Louveteaux et Jeannettes theme */
$ddc-background-louveteaux-et-jeannettes: url("~assets/img/ddc-papier-dechire-louveteaux-et-jeannettes.png");
$ddc-color-louveteaux-et-jeannettes-primary: #ff8300;
$ddc-color-louveteaux-et-jeannettes-secondary: #d16a1e;
$ddc-color-louveteaux-et-jeannettes-tertiary: #db770e;
$ddc-color-louveteaux-et-jeannettes-quaternary: #f7b05b;
$ddc-color-louveteaux-et-jeannettes-muted-primary: #f7cf8e;
$ddc-color-louveteaux-et-jeannettes-muted-secondary: #ffe0b7;
$ddc-color-louveteaux-et-jeannettes-muted-tertiary: #fff4e0;
$ddc-color-louveteaux-et-jeannettes-warn: #ea5633;

/* Farfadets theme */
$ddc-background-farfadets: url("~assets/img/ddc-papier-dechire-farfadets.png");
$ddc-color-farfadets-primary: #65bc99;
$ddc-color-farfadets-secondary: #48a680;
$ddc-color-farfadets-tertiary: #78BB94;
$ddc-color-farfadets-quaternary: #7ECC9E;
$ddc-color-farfadets-muted-primary: #a9e4c8;
$ddc-color-farfadets-muted-secondary: #c9f5e5;
$ddc-color-farfadets-muted-tertiary: #eafff7;
$ddc-color-farfadets-warn: #e85f5c;


/* Style parameters List */
$colors:
  "master"
    $ddc-background-master
    $ddc-color-master-primary $ddc-color-master-secondary $ddc-color-master-tertiary $ddc-color-master-quaternary
    $ddc-color-master-muted-primary $ddc-color-master-muted-secondary $ddc-color-master-muted-tertiary
    $ddc-color-master-warn,
  "marins"
    $ddc-background-marins
    $ddc-color-marins-primary $ddc-color-marins-secondary $ddc-color-marins-tertiary $ddc-color-marins-quaternary
    $ddc-color-marins-muted-primary $ddc-color-marins-muted-secondary $ddc-color-marins-muted-tertiary
    $ddc-color-marins-warn,
  "audace"
    $ddc-background-audace
    $ddc-color-audace-primary $ddc-color-audace-secondary $ddc-color-audace-tertiary $ddc-color-audace-quaternary
    $ddc-color-audace-muted-primary $ddc-color-audace-muted-secondary $ddc-color-audace-muted-tertiary
    $ddc-color-audace-warn,
  "compagnons"
    $ddc-background-compagnons
    $ddc-color-compagnons-primary $ddc-color-compagnons-secondary $ddc-color-compagnons-tertiary $ddc-color-compagnons-quaternary
    $ddc-color-compagnons-muted-primary $ddc-color-compagnons-muted-secondary $ddc-color-compagnons-muted-tertiary
    $ddc-color-compagnons-warn,
  "pionniers-et-caravelles"
    $ddc-background-pionniers-et-caravelles
    $ddc-color-pionners-et-caravelles-primary $ddc-color-pionners-et-caravelles-secondary $ddc-color-pionners-et-caravelles-tertiary $ddc-color-pionners-et-caravelles-quaternary
    $ddc-color-pionners-et-caravelles-muted-primary $ddc-color-pionners-et-caravelles-muted-secondary $ddc-color-pionners-et-caravelles-muted-tertiary
    $ddc-color-pionners-et-caravelles-warn,
  "scouts-et-guides"
    $ddc-background-scouts-et-guides
    $ddc-color-scouts-et-guides-primary $ddc-color-scouts-et-guides-secondary $ddc-color-scouts-et-guides-tertiary $ddc-color-scouts-et-guides-quaternary
    $ddc-color-scouts-et-guides-muted-primary $ddc-color-scouts-et-guides-muted-secondary $ddc-color-scouts-et-guides-muted-tertiary
    $ddc-color-scouts-et-guides-warn,
  "louveteaux-et-jeannettes"
    $ddc-background-louveteaux-et-jeannettes
    $ddc-color-louveteaux-et-jeannettes-primary $ddc-color-louveteaux-et-jeannettes-secondary $ddc-color-louveteaux-et-jeannettes-tertiary $ddc-color-louveteaux-et-jeannettes-quaternary
    $ddc-color-louveteaux-et-jeannettes-muted-primary $ddc-color-louveteaux-et-jeannettes-muted-secondary $ddc-color-louveteaux-et-jeannettes-muted-tertiary
    $ddc-color-louveteaux-et-jeannettes-warn,
  "farfadets"
    $ddc-background-farfadets
    $ddc-color-farfadets-primary $ddc-color-farfadets-secondary $ddc-color-farfadets-tertiary $ddc-color-farfadets-quaternary
    $ddc-color-farfadets-muted-primary $ddc-color-farfadets-muted-secondary $ddc-color-farfadets-muted-tertiary
    $ddc-color-farfadets-warn;
